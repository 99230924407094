import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import CardLaudo from '../components/CardLaudos';
import axios from "axios";
import fileDownload from 'js-file-download';
import {isMobile} from 'react-device-detect';

//<DataGrid rows={rows} columns={columns} hideFooterPagination hideFooterSelectedRowCount/>

export default function BasicEditingGrid() {
    const [rows, setRows] = React.useState([]);
    const [laudos, setLaudos] = React.useState(false)    

    React.useEffect(() => {
        axios.get('https://servidor1.amepg.org.br/laudos/site/pesquisa-prontuario?prontuario=' + sessionStorage.getItem("user"))
        .then((resp) => {
            if(resp.status !== 204) {
                setRows(resp.data);
                setLaudos(true);
            }else{
                setLaudos(false);
            }})
        .catch(error => {console.log('error', error); setLaudos(false)});
    }, [])
  if(!isMobile){
    return (
    
      <Container maxWidth='xl' sx={{ marginTop: 4}}>
          {laudos  ? 
              <DataGrid rows={rows} columns={columns} hideFooterPagination hideFooterSelectedRowCount/>
          : 
            <Typography> Não há laudos para esse paciente!</Typography>
          }
      </Container>
    )
  }
  return(
    <Container maxWidth='xl' sx={{ marginTop: 4}}>
      {
      laudos  ? 
       
        rows.map((item) =>(
          <CardLaudo nomeExame={item.exame} 
              arquivo={item.fileId} 
              dtRealizado={item.dataAtendimento} 
              dtLaudo={item.dataLaudo}
              senha={item.senha}
              paciente={item.nome}
          />
          ))
      : 
        <Typography> Não há laudos para esse paciente!</Typography>}

    </Container>  
  );  
}


const columns = [
    { field: 'nome', headerName: 'Nome', width: 320, editable: false },
    { field: 'fileId', headerName: 'Arquivo', width: 90, editable: false },
    { field: 'exame', headerName: 'Exame', width: 370, editable: false },
    { field: 'dataAtendimento', headerName: 'Data Atendimento', sortable: 'DESC',width: 180, editable: false, headerAlign: 'center', align: 'center',
     valueFormatter: params => 
     new Date(params?.value).toLocaleString([], {day: 'numeric', month: 'numeric', year: 'numeric'})},

    { field: 'dataLaudo', headerName: 'Data Laudo', width: 180, editable: false, headerAlign: 'center', align: 'center',
    valueFormatter: params => 
     new Date(params?.value).toLocaleString([], {day: 'numeric', month: 'numeric', year: 'numeric'})},

    { field: 'action', headerName: 'Ação', width: 250, headerAlign: 'center', align: 'center', sortable: false, editable: false, disableClickEventBubbling: true,
    renderCell: (params) => {
        const onClick = (e) => {
          const currentRow = params.row;
          console.log(currentRow.arquivo_id)
          axios.get('https://servidor1.amepg.org.br/laudos/download?fileId=' + currentRow.fileId, {responseType: 'blob'})
          .then((resp) => {
            fileDownload(resp.data, currentRow.fileId + ".pdf")
          })
          .catch(error => console.log('error', error));
        };
        
        return (
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" color="warning" size="small" onClick={(onClick)}>Download</Button>
          </Stack>
        );
    },
}
];
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import fileDownload from 'js-file-download';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import axios from "axios";

export default function CardLaudo({nomeExame, arquivo, dtRealizado, dtLaudo, senha, paciente}) {
  const [download, setDownload] = React.useState(false);

  const startDownload = () => {
    setDownload(true);
    console.log(download);
    axios.get('https://servidor1.amepg.org.br/laudos/download?fileId=' + arquivo, {responseType: 'blob'})
        .then((resp) => {
          fileDownload(resp.data, arquivo + ".pdf");
          setDownload(false);
        })
        .catch(error => console.log('error', error));
  };

  function formatFields(){
      let atendimento = new Date (Date.parse(dtRealizado));
      let laudo = new Date(Date.parse(dtLaudo));

      dtRealizado = atendimento.toLocaleString('default',{day: 'numeric', month: 'numeric', year: 'numeric'});
      dtLaudo = laudo.toLocaleString('default',{day: 'numeric', month: 'numeric', year: 'numeric'});
  };

  formatFields();

   return (
      <Card width="100%" sx={{m: 1, background: "#F0F8FF"}}>
        <CardContent>
          <Typography variant="h5" component="div">
            {nomeExame}
          </Typography>
          <Typography variant="body2" component="div" sx={{ mb: 1.5 }}>
            Cod.: {arquivo}
          </Typography>
          <Typography variant="body2" component="div" sx={{ mb: 1.5 }}>
            Paciente: {paciente}
          </Typography>
          
          <Typography color="text.secondary">
            Realizado: {dtRealizado}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Laudado: {dtLaudo}
          </Typography>
          
        </CardContent>
        <CardActions sx={{ mb: 1, mt: -3}}>
        <Button size="large"><CloudDownloadIcon fontSize="large" color="blue" onClick={startDownload}/></Button>
        {download ? 
            <>
                <img src = "/img/loading.gif" width={15} height={15} alt="download" sx={{ ml:1, mt:0.5 }}/>
                <Typography sx={{ ml:1, mt:0.5 }} variant="body2">Baixando...</Typography>
            </>
            : <></>
        }
        </CardActions>
      </Card>
    );
  }
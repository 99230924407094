import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function ButtonAppBar({setLogged}) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
           <Box>
                <img src="/img/logo.png" alt="" width={160} height={60} style={{margin: 10}}/> 
            </Box> 
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Laudos
          </Typography>
          <Button color="inherit" onClick={() => {setLogged(false); sessionStorage.removeItem('user')}}>Sair</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
import './App.css';
import ButtonAppBar from './components/Header';
import BasicEditingGrid from './components/Index';
import Login from './components/Login';
import { useEffect, useState } from 'react';

function App() {
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("user");
    if (loggedInUser) {
        setLogged(true);
    }
    
    localStorage.removeItem("user");
    
  }, []);
  return (
    <div className="App">      
      {!logged && <Login setLogged={setLogged}/> }
       { logged && <ButtonAppBar setLogged={setLogged}/>}
       { logged && <BasicEditingGrid />}      
    </div>
  );
}

export default App;

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alerts from './Alerts';
import axios from "axios";

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login({setLogged}) {
    const [alert, setAlert] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        // Verifica erro no login
        if(data.get('cpf') === ''){ setAlert(true); setAlertMessage("CPF não pode estar vazio"); console.log("cpfvazio");return}
        if(data.get('anoNascimento') === ''){ setAlert(true); setAlertMessage("Ano nascimento não pode estar vazio"); return}
        if(data.get('anoNascimento').length > 4){ setAlert(true); setAlertMessage("Digite apenas o ano do seu nascimento com 4 digitos"); return}

        axios.post('https://servidor1.amepg.org.br/v1/login/laudos', {
            user : data.get('cpf'), 
            password: data.get('anoNascimento')})
            .then((resp) => {
                if(resp.status === 200){setLogged(true); sessionStorage.setItem('user', resp.data.user)}
                else{setAlert(true); setAlertMessage('Falha ao logar!')}
            }).catch((error) => {
              setAlert(true); 
              setAlertMessage('Falha ao logar!')
              console.log(error.response)
            })
    };

  return (
    
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">

        {alert ? <Alerts message={alertMessage} /> : <></> }
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          
          <img src='/img/logo_azul.png' width={200}></img>          

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="cpf"
              label="CPF"
              name="cpf"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="anoNascimento"
              label="Ano Nascimento"
              type="password"
              id="anoNascimento"
              inputProps={{ maxLength: 4 }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              ACESSAR
            </Button>
          </Box>
        </Box>
        
      </Container>
    </ThemeProvider>
  );
}